<template>
  <div>
    <b-container>
      <b-jumbotron>
        <template v-slot:header>OHF Project</template>
        <template v-slot:lead>
          {{jumbotron_lead}}
        </template>
      </b-jumbotron>
    </b-container>
  </div>
</template>

<script>
export default {
  data(){
    return {
      
    }
  }
}
</script>
